import React from "react";
import MainPage from "./components";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import ContactUs from "./components/contact-us";
import About from "./components/about";
import CategorySpecificPage from "./components/category-specific-page.tsx";

function App() {
  return (
    <>
      <MainPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route
          path="contact"
          element={
            <div style={{ paddingTop: "20%" }}>
              <ContactUs />
            </div>
          }
        />
        <Route path=":category" element={<CategorySpecificPage />} />
      </Routes>
    </>
  );
}

export default App;
