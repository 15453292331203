import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../hooks/useMediaQuery";

export default function MainPage() {
  const isWebView = useMediaQuery("(min-width: 1200px)");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "70px",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#3d52a0",
        position: "fixed",
        zIndex: "100",
        top: "0",
        left: "0",
      }}
    >
      <div style={{ display: isWebView ? "block" : "none" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: isWebView ? "15%" : "100%",
          padding: "25px",
          margin: "0 20px",
        }}
      >
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none",
            fontFamily: "Young Serif",
            fontSize: "20px",
          }}
        >
          Home
        </Link>
        <Link
          to="/contact"
          style={{
            color: "white",
            textDecoration: "none",
            fontFamily: "Young Serif",
            fontSize: "20px",
          }}
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
}
