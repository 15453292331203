import React from "react";
import { CATEGORIES } from "../../constants/categories";
import { useMediaQuery } from "../../hooks/useMediaQuery";

import { Link } from "react-router-dom";
import { URL_PREFIX } from "../../constants/backend";

export default function ExploreBy() {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    const fetchedProductCategories = async () => {
      const response = await fetch(`${URL_PREFIX}/`);
      const json = await response.json();
      if (response.ok) {
        setCategories(json);
      }
    };
    fetchedProductCategories();
  }, []);

  const isWebView = useMediaQuery("(min-width: 1200px)");
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          fontFamily: "Young Serif",
        }}
      >
        Explore by Categories
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "15px",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          textAlign: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {categories.map(
          (category: {
            category: string;
            displayName: string;
            icon: string;
            backgroundColor: string;
          }) => {
            return (
              <div
                style={{
                  margin: isWebView ? "1%": '2%',
                  backgroundColor: category.backgroundColor ?? "#DA7b93",
                  borderRadius: "15%",
                  padding: isWebView ? "0.5%" : "1%",
                  whiteSpace: isWebView ? "initial" : "break-spaces",
                  width: isWebView ? "17%" : "42%",
                  overflow: 'wrap'
                }}
              >
                <Link
                  to={`/${category.category}`}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontFamily: "Young Serif",
                    fontSize: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        src={category.icon}
                        width={'55%'}
                        style={{
                          borderRadius: "6px",
                          animation: "fadeIn 0.5s",
                        }}
                        alt="cadeaux-images"
                      />
                    </div>
                    {category.displayName}
                  </div>
                </Link>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}
