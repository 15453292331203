import React from "react";
import homePageImage from "../../images/home-page-image.jpg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import ExploreBy from "./explore-by";
import InquireNow from "../shared/inquire-now";
import ContactUs from "../contact-us";
import { URL_PREFIX } from "../../constants/backend";
import styled, { keyframes } from "styled-components";

// const slideIn = keyframes `
// 0% {
//   opacity: 0;
//   transform: translateY(-10);
// }

// 100% {
//   opacity: 100%;
//   transform: translateY(0);
// }
// `

// const AnimatedHomeImage = styled.img`
// animation-name: ${slideIn};
// animation-duration: 4s;
// animation-iteration-count: infinite;
// `

const productsWeProvide = [
  {
    displayName: "Sustainable Selections",
    image:
      "https://i0.wp.com/himalayanpine.co/wp-content/uploads/2024/05/Wine-pink-Date-Palm-4.jpg?fit=1080%2C1080&ssl=1",
  },
  {
    displayName: "Hampers",
    image:
      "https://imagesm.plexussquare.in/URBANGEAR/Images/11-04-2024/1712831754398.jpg",
  },
];

function Home() {
  const isWebView = useMediaQuery("(min-width: 1200px)");
  const [homePosters, setHomePosters] = React.useState([]);
  React.useEffect(() => {
    const fetchedHomePosters = async () => {
      const response = await fetch(`${URL_PREFIX}/home-posters`);
      const json = await response.json();
      if (response.ok) {
        setHomePosters(json);
      }
    };
    fetchedHomePosters();
  }, []);
  const [homePagePosterIdx, setHomePagePosterIdx] = React.useState(0);
  React.useEffect(() => {
    if (!!homePosters.length) {
      const intervalId = setInterval(() => {
        setHomePagePosterIdx((homePagePosterIdx + 1) % homePosters.length);
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  });
  // return (
  //   <>
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: isWebView ? "row" : "column",
  //         padding: "2%",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           padding: isWebView ? 0 : "6px",
  //           margin: isWebView ? "9% 0% 1% 8%" : "20%",
  //         }}
  //       >
  //         <p
  //           style={{
  //             fontWeight: "bold",
  //             fontSize: "20px",
  //             margin: "1% 0",
  //           }}
  //         >
  //           Cadeaux
  //         </p>
  //         <p
  //           style={{
  //             fontSize: "20px",
  //             margin: "1% 0",
  //           }}
  //         >
  //           Elevating your gifting journey with unparalleled experiences!
  //         </p>
  //         <p
  //           style={{
  //             fontSize: "30px",
  //             margin: "5% 0",
  //           }}
  //         >
  //           Tailored to deliver:
  //         </p>
  //         <div
  //           style={{
  //             fontWeight: "bold",
  //             fontSize: "40px",
  //             margin: "1% 0",
  //             //border: 'medium solid #e1e1e1',
  //             borderRadius: '24px',
  //             //padding: '3%',
  //             fontFamily: "Young Serif",
  //           }}
  //         >{`${productsWeProvide[productsWeProvideIdx].displayName}`}</div>
  //         {/* <h1>
  //           We believe that gifting is an art, and each gift should be a
  //           masterpiece!
  //         </h1>
  //         <p>
  //           We specialize in delivering excellent customized gifts, creating
  //           meaningful corporate gifting experiences. With years of experience,
  //           we take pride in our commitment to craftsmanship, attention to
  //           detail, and personalized service. Our carefully curated collection
  //           reflects our dedication to making a lasting impression. Trust us to
  //           elevate your gifting experience and strengthen your business
  //           relationships.
  //         </p> */}
  //       </div>
  //       <img
  //         src={productsWeProvide[productsWeProvideIdx].image}
  //         alt="home-page"
  //         width={isWebView ? "30%" : "100%"}
  //         style={{
  //           padding: isWebView ? 0 : "6px",
  //           margin: isWebView ? "5% 10% 1% 1%" : "20%",
  //         }}
  //       />
  //     </div>
  //     <InquireNow />
  //     <ExploreBy />
  //     <div style={{ padding: "2%" }}>
  //       <ContactUs />
  //     </div>
  //   </>
  // );
  return (
    <>
      <div>
        {!!homePosters.length && (
          <div
            style={{
              display: "flex",
              marginTop: isWebView ? "7%" : "25%",
              flexDirection: "row",
              height: isWebView ? "550px" : "330px",
              width: "100%",
            }}
          >
            {homePagePosterIdx - 1 >= 0 && (
              <img
                src={homePosters[homePagePosterIdx - 1]}
                alt="home-page"
                width={"25%"}
                height={isWebView ? "300px" : "180px"}
                style={{
                  //padding: "1%",
                  //margin: isWebView ? "5% 10% 1% 1%" : "20%",
                  position: "absolute",
                  top: isWebView ? "25%" : "22%",
                  borderRadius: "20px",
                  border: "solid 1px black",
                }}
              />
            )}
            <center>
              <img
                src={homePosters[homePagePosterIdx]}
                alt="home-page"
                height={isWebView ? "500px" : "300px"}
                style={{
                  //padding: "1%",
                  //margin: isWebView ? "5% 10% 1% 1%" : "20%",
                  position: "absolute",
                  left: "20%",
                  width: "60%",
                  zIndex: 1,
                  borderRadius: "20px",
                  border: "solid 1px black",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              />
            </center>
            {homePagePosterIdx + 1 <= homePosters.length - 1 && (
              <img
                src={homePosters[homePagePosterIdx + 1]}
                alt="home-page"
                width={"25%"}
                height={isWebView ? "300px" : "180px"}
                style={{
                  //padding: "1%",
                  //margin: isWebView ? "5% 10% 1% 1%" : "20%",
                  position: "absolute",
                  right: "1%",
                  top: isWebView ? "25%" : "22%",
                  borderRadius: "20px",
                  border: "solid 1px black",
                }}
              />
            )}
          </div>
        )}
      </div>
      <InquireNow />
      <ExploreBy />
      <div
        style={{
          padding: "2%",
        }}
      >
        <ContactUs />
      </div>
    </>
  );
}

export default Home;
