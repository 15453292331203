import React from "react";
import { useParams } from "react-router-dom";
import { CATEGORIES } from "../../constants/categories";
import InquireNow from "../shared/inquire-now";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import Select from "react-select";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { URL_PREFIX } from "../../constants/backend";
import ExploreBy from "../home/explore-by";

const filterOptions = [
  { value: "ascending", label: "Sort by Price: Lowest to Highest" },
  { value: "descending", label: "Sort by Price: Highest to Lowest" },
];

const ImageSlideshow = ({ images }: { images: Array<string> }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const isWebView = useMediaQuery("(min-width: 1000px)");
  const motionImageObjects = isWebView
    ? {
        onMouseEnter: images.length > 1 ? () => setCurrentIndex(1) : () => {},
        onMouseLeave: images.length > 1 ? () => setCurrentIndex(0) : () => {},
      }
    : {
        onClick:
          images.length > 1
            ? () => setCurrentIndex((currentIndex + 1) % 2)
            : () => {},
      };

  const handleImageChangeClick = (isNextClicked: boolean) => {
    if (isNextClicked) {
      setCurrentIndex((currentIndex + 1) % (images.length ?? 1));
    } else {
      setCurrentIndex(
        (currentIndex - 1 + images.length) % (images.length ?? 1)
      );
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* {images.length > 1 && (
        <FaChevronLeft
          onClick={() => handleImageChangeClick(false)}
          style={{
            position: "absolute",
            top: "50%",
          }}
        />
      )} */}
      <img
        width="100%"
        height="300px"
        style={{
          borderRadius: "6px",
          animation: "fadeIn 0.5s",
        }}
        alt="cadeaux-images"
        src={images[currentIndex]}
        {...motionImageObjects}
      />
      {/* {images.length > 1 && (
        <FaChevronRight
          onClick={() => handleImageChangeClick(true)}
          style={{
            position: "absolute",
            top: "50%",
            left: "95%",
          }}
        />
      )} */}
    </div>
  );
};

const calculateDiscount = (cost: string, discount: string) => {
  const intCost = parseInt(cost);
  const intDiscount = parseInt(discount);
  return Math.round(intCost - (intCost * intDiscount) / 100);
};

export default function CategorySpecificPage() {
  const isWebView = useMediaQuery("(min-width: 1000px)");
  const { category } = useParams();

  // const [priceState, setPriceState] = React.useState({
  //   value: "descending",
  //   label: "Sort by Price: Highest to Lowest",
  // });

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    const fetchedProducts = async () => {
      const response = await fetch(`${URL_PREFIX}/${category}`);
      const json = await response.json();
      if (response.ok) {
        setProducts(json);
      }
    };
    fetchedProducts();
  }, []);

  // const handleSelectChange = (event) => {
  //   setPriceState(event);
  // };
  return (
    <>
      {/* <div>
        <div
          style={{
            paddingTop: isWebView ? "7%" : "25%",
            margin: isWebView ? "0 5%" : "0 9%",
          }}
        >
          <center>
            <div
              style={{
                fontFamily: "Young Serif",
                marginBottom: "25px",
                backgroundColor: "#c94d46",
                color: "white",
                padding: "15px",
                alignContent: "center",
                display: "inline-flex",
                borderRadius: "10px",
              }}
            >
              The prices are inclusive of the customisation and branding cost!
            </div>
          </center>
          <div
            style={{
              width: isWebView ? "30%" : "100%",
            }}
          >
            <Select
              defaultValue={priceState}
              onChange={handleSelectChange}
              options={filterOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontFamily: "Young Serif",
                }),
              }}
            />
          </div>
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flexWrap: "wrap",
          paddingTop: isWebView ? "7%" : "25%",
          margin: isWebView ? "0 1%" : "0 3%",
        }}
      >
        {products.map(
          (
            { cost, images, displayName, discount, usp, specifications },
            index
          ) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: isWebView ? "1.5%" : "4%",
                  margin: isWebView ? "0.5%" : "2%",
                  borderRadius: "12px",
                  border: "1px solid grey",
                  width: isWebView ? "20%" : "100%",
                  justifyContent: "space-between",
                }}
              >
                <ImageSlideshow images={images} />
                <div style={{ fontFamily: "Roboto" }}>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: 22,
                      fontFamily: "Young Serif",
                      marginTop: "1%",
                    }}
                  >
                    {displayName}
                  </div>
                  {!!usp && (
                    <div
                      style={{
                        backgroundColor: "#3d52a0",
                        color: "white",
                        fontWeight: 500,
                        padding: "2%",
                        margin: "1.5% 0",
                        borderRadius: "6px",
                        display: "inline-block",
                      }}
                    >
                      {usp}
                    </div>
                  )}
                  {specifications && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {Object.keys(specifications).map((property: any) => (
                        <div>{`${property}: ${specifications[property]}`}</div>
                      ))}
                    </div>
                  )}
                  {discount ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5%",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          fontWeight: 600,
                          fontSize: 20,
                        }}
                      >{`Rs ${calculateDiscount(cost, discount)}/-`}</div>
                      <div
                        style={{
                          textDecoration: "line-through",
                          marginRight: "10px",
                        }}
                      >
                        {`Rs ${cost}/-`}
                      </div>
                      <div
                        style={{ color: "#166266" }}
                      >{`${discount} Off`}</div>
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: "5%", fontWeight: 600, fontSize: 20 }}
                    >
                      {`Rs ${cost}/-`}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
      <InquireNow />
    </>
  );
}
