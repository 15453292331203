import React from "react";
import InquireNow from "../shared/inquire-now";

function ContactUs() {
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          fontFamily: "Young Serif",
        }}
      >
        Contact Us
      </h2>
      <h4
        style={{
          textAlign: "center",
          fontFamily: "Young Serif",
        }}
      >
        We appreciate your interest and look forward to hearing from you. You
        can contact us at:
        <h3>Phone number: 8010278125</h3>
        <h3>Email: cadeaux.corporategifts@gmail.com</h3>
      </h4>
      <InquireNow />
    </>
  );
}

export default ContactUs;
