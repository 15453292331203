import React from "react";

export default function InquireNow() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "30px",
        right: "30px",
        backgroundColor: "#295c4b",
        cursor: "pointer",
        padding: "12px",
        borderRadius: "20px",
      }}
    >
      <a
        href={`https://api.whatsapp.com/send?text=Hey!%20I%20wanted%20to%20enquire%20about%20your%20products&phone=918010278125`}
        target="_blank"
        rel="noreferrer"
        style={{
          color: "white",
          textDecoration: "none",
          fontFamily: "Young Serif",
        }}
      >
        INQUIRE NOW!
      </a>
    </div>
  );
}
